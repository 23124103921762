var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.openModal } },
    [
      _c("el-input", {
        attrs: {
          placeholder: "客户名称",
          value: _vm.name || _vm.value,
          disabled: _vm.disabled,
        },
      }),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }